/**
 * @generated SignedSource<<c290f7ff37f238e4ade6a2903a186803>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type contentHeadlineTagContent$data = {
  readonly " $fragmentSpreads": FragmentRefs<"hooksContentHeadlineTagContent">;
  readonly " $fragmentType": "contentHeadlineTagContent";
};
export type contentHeadlineTagContent$key = {
  readonly " $data"?: contentHeadlineTagContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"contentHeadlineTagContent">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "flag",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "contentHeadlineTagContent",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "hooksContentHeadlineTagContent",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ArticleTypeSegment",
              "kind": "LinkedField",
              "name": "types",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ArticleType",
                  "kind": "LinkedField",
                  "name": "value",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "FormattedValue",
                      "kind": "LinkedField",
                      "name": "description",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "text",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "helpersCheckIsStyleArticle",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SectionSegment",
                  "kind": "LinkedField",
                  "name": "sections",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Section",
                      "kind": "LinkedField",
                      "name": "value",
                      "plural": true,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "type": "Article",
          "abstractKey": null
        },
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "SectionSegment",
          "kind": "LinkedField",
          "name": "sections",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Section",
              "kind": "LinkedField",
              "name": "value",
              "plural": true,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "hooksContentHelperContent",
          "selections": [
            {
              "kind": "InlineDataFragmentSpread",
              "name": "helpersSanitizeArticleTypeEntityIdsArticle",
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ArticleTypeSegment",
                      "kind": "LinkedField",
                      "name": "types",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ArticleType",
                          "kind": "LinkedField",
                          "name": "value",
                          "plural": true,
                          "selections": (v2/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "Article",
                  "abstractKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "helpersSanitizeAuthorTypesArticle",
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Author",
                      "kind": "LinkedField",
                      "name": "authors",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "types",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "Article",
                  "abstractKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "helpersSanitizedAuthorEntityIdsArticle",
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Author",
                      "kind": "LinkedField",
                      "name": "authors",
                      "plural": true,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "type": "Article",
                  "abstractKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            },
            (v3/*: any*/)
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};
})();

(node as any).hash = "cfab9c3509b9d4c49182940ec6d66fbf";

export default node;
