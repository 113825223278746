import { notEmpty } from "@product/scmp-sdk";
import first from "lodash/first";
import last from "lodash/last";
import { useMemo } from "react";
import { graphql, readInlineData } from "react-relay";

import { isSCMPColumnist, isSCMPEditorial, isSCMPPR } from "scmp-app/components/author/helpers";
import { article as articleData } from "scmp-app/data";
import type { hooksContentHelperContent$key } from "scmp-app/queries/__generated__/hooksContentHelperContent.graphql";

import {
  sanitizeArticleTypeEntityIds,
  sanitizeAuthorTypes,
  sanitizedAuthorEntityIds,
} from "./helpers";

const allowedTypes = new Set<string>([
  articleData.types.analysis.entityId,
  articleData.types.explainer.entityId,
  articleData.types.factCheck.entityId,
  articleData.types.letters.entityId,
  articleData.types.obituary.entityId,
  articleData.types.profile.entityId,
  articleData.types.qa.entityId,
  articleData.types.recap.entityId,
  articleData.types.review.entityId,
]);

export const useContentHelper = (reference: hooksContentHelperContent$key) => {
  const content = readInlineData(
    graphql`
      fragment hooksContentHelperContent on Content @inline {
        ...helpersSanitizeArticleTypeEntityIdsArticle
        ...helpersSanitizeAuthorTypesArticle
        ...helpersSanitizedAuthorEntityIdsArticle
        flag
      }
    `,
    reference,
  );

  const authorIds = useMemo(() => sanitizedAuthorEntityIds(content), [content]);
  const authorTypes = useMemo(() => sanitizeAuthorTypes(content), [content]);
  const typeIds = useMemo(() => sanitizeArticleTypeEntityIds(content), [content]);

  /*
    Condition is after sanitized article data, there are one entityId and its first entityId is
    either type column or opinion
  */
  const isScmpOpinionType = useMemo(() => {
    if (typeIds.length !== 1) return false;

    const firstArticleTypeId = first(typeIds) ?? "";
    return (
      articleData.types.opinion.entityId === firstArticleTypeId ||
      articleData.types.column.entityId === firstArticleTypeId
    );
  }, [typeIds]);

  /*
    Condition is after sanitized article data, there are two entityIds and its first entityId is
    type column
  */
  const isEditorialOpinionType = useMemo(() => {
    if (typeIds.length !== 2) return false;

    const firstArticleTypeId = first(typeIds) ?? "";
    return articleData.types.column.entityId === firstArticleTypeId;
  }, [typeIds]);

  /*
    Condition is after sanitized article data, there are two entityIds and its first entityId is
    type column and it's second/last entityId is type editorial
  */
  const isEditorialHeadlineType = useMemo(() => {
    if (typeIds.length !== 2) return false;

    const firstArticleTypeId = first(typeIds) ?? "";
    const lastArticleTypeId = last(typeIds) ?? "";
    return (
      articleData.types.column.entityId === firstArticleTypeId &&
      articleData.types.editorial.entityId === lastArticleTypeId
    );
  }, [typeIds]);

  /*
    Condition is after sanitized article data, its first entityId belongs to allowedTypes
  */
  const isSpecialArticleType = useMemo(() => {
    if (typeIds.length === 0) return false;

    const firstArticleTypeId = first(typeIds) ?? "";
    return allowedTypes.has(firstArticleTypeId);
  }, [typeIds]);

  const isAuthorSCMPColumnist = useMemo(() => isSCMPColumnist(authorTypes), [authorTypes]);
  const isAuthorSCMPEditorial = useMemo(() => isSCMPEditorial(authorIds), [authorIds]);
  const isAuthorSCMPPR = useMemo(() => isSCMPPR(authorIds), [authorIds]);
  const isContentWithFlag = useMemo(() => notEmpty(content.flag), [content.flag]);
  const isRecapArticleType = useMemo(
    () => typeIds.includes(articleData.types.recap.entityId),
    [typeIds],
  );

  return {
    isAuthorSCMPColumnist,
    isAuthorSCMPEditorial,
    isAuthorSCMPPR,
    isContentWithFlag,
    isEditorialHeadlineType,
    isEditorialOpinionType,
    isRecapArticleType,
    isScmpOpinionType,
    isSpecialArticleType,
  };
};
