import type { TooltipProps } from "@mui/material/Tooltip";
import type { FunctionComponent } from "react";

import { StyledTooltip } from "./styles";

export type Props = TooltipProps;
export { tooltipClasses } from "@mui/material/Tooltip";

export const Tooltip: FunctionComponent<Props> = ({ children, className, ...props }) => (
  <StyledTooltip {...props} classes={{ popper: className }}>
    {children}
  </StyledTooltip>
);

Tooltip.displayName = "Tooltip";
