import { findFirstNonEmptyString, notEmpty } from "@product/scmp-sdk";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { getContentSponsor } from "scmp-app/components/content/content-sponsor-tag/helpers";
import type { contentHeadlineContent$key } from "scmp-app/queries/__generated__/contentHeadlineContent.graphql";

import { ContentHeadlineTag } from "./content-headline-tag";
import { H1Container, H2Container, Headline, SponsorHeadline } from "./styles";

export type ContentHeadlineVariant = "magazines-style";

export type Props = {
  className?: string;
  componentTag?: "h1" | "h2";
  hideTag?: boolean;
  hideTooltip?: boolean;
  preferSocialHeadline?: boolean;
  reference: contentHeadlineContent$key;
  skipHeadlineSponsorStyle?: boolean;
  skipHighlight?: boolean;
  useSectionTags?: boolean;
  variant?: ContentHeadlineVariant;
};

export const ContentHeadline: FunctionComponent<Props> = ({
  className,
  componentTag = "h2",
  hideTag,
  hideTooltip,
  preferSocialHeadline,
  reference: reference_,
  skipHeadlineSponsorStyle = true,
  skipHighlight,
  useSectionTags,
  variant,
}) => {
  const content = useFragment(
    graphql`
      fragment contentHeadlineContent on Content {
        headline
        socialHeadline
        ...contentHeadlineTagContent
        ...helpersUseContentSponsorContent
      }
    `,
    reference_,
  );

  const { headline, socialHeadline } = content;

  const contentSponsor = getContentSponsor(content);
  const isSponsorArticle = notEmpty(contentSponsor?.name);

  const sanitizedHeadline = preferSocialHeadline
    ? findFirstNonEmptyString(socialHeadline, headline)
    : findFirstNonEmptyString(headline, socialHeadline);

  if (!sanitizedHeadline) return null;

  const isShowSponsorHeadline = isSponsorArticle && !skipHeadlineSponsorStyle;

  const ContainerWithTag = componentTag === "h1" ? H1Container : H2Container;

  return (
    <ContainerWithTag $variant={variant} className={className}>
      {!hideTag && (
        <ContentHeadlineTag
          hideTooltip={hideTooltip}
          reference={content}
          useSectionTags={useSectionTags}
          variant={variant}
        />
      )}
      {isShowSponsorHeadline ? (
        <SponsorHeadline data-skip-highlight={skipHighlight}>{sanitizedHeadline}</SponsorHeadline>
      ) : (
        <Headline data-skip-highlight={skipHighlight}>{sanitizedHeadline}</Headline>
      )}
    </ContainerWithTag>
  );
};

ContentHeadline.displayName = "ContentHeadline";
